import React, { useEffect, useState } from "react";
import { FaChevronUp } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

const RightSideAd = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);
  const location = useLocation();

  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.async = true;
      script.onload = initializeGoogleAds;
      document.head.appendChild(script);
    };
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    if (isDesktop) {
      loadGoogleAdsScript();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  const initializeGoogleAds = () => {
    if (window.googletag && typeof window.googletag === "object") {
      window.googletag.cmd.push(() => {
        let adSlot = window.googletag.defineSlot(
          "/23055822891/skyscraper1_160_600",
          [[160, 600]],
          "div-gpt-ad-1726569975702-0"
        );
        adSlot.addService(window.googletag.pubads());
        window.googletag.enableServices();
        window.googletag.display("div-gpt-ad-1726569975702-0");
      });
    } else {
      console.error(
        "googletag is not defined. Google Ad Manager library might not be loaded."
      );
    }
  };

  const hideAd = () => {
    setIsVisible(false);
  };

  if (!isDesktop || !isVisible) {
    return null;
  }

  return (
    <div
      className="sideAd"
      style={{
        position: "fixed",
        right: "0",
        marginTop: "10px",
        marginRight: "50px",
        height: "600px",
        width: "160px",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#E9EAEC",
        borderTop: "1px solid #ddd",
        padding: "10px 0",
      }}
    >
      <button
        onClick={hideAd}
        style={{
          position: "absolute",
          bottom: "-25px",
          border: "2px",
          borderColor: "#000",
          cursor: "pointer",
          color: `${location.pathname === "/single-blog" ? "#fff" : "#000"}`,
          borderRadius: "3px",
          zIndex: 1001,
        }}
      >
        <FaChevronUp />
      </button>
      <div
        id="div-gpt-ad-1726569975702-0"
        style={{
          width: "160px",
          height: "600px",
          overflow: "hidden",
        }}
      ></div>
    </div>
  );
};

export default RightSideAd;
