import React from "react";

const CelebrityCard = ({ data, handleCardClick }) => {
  return (
    <div className="border p-2">
      <div
        className="cursor-pointer"
        onClick={() => {
          const formattedTitle = data?.title?.replace(/\s+/g, "-");
          handleCardClick(formattedTitle);
        }}
      >
        <div className="w-full h-[300px]">
          <img
            src={data?.image}
            alt={data?.title}
            className="w-full h-full object-cover object-top"
            loading="lazy"
            srcSet={`${data?.image}?w=300 300w, ${data?.image}?w=600 600w`}
          />
        </div>
        <div className="flex justify-end">
          {data?.sourceBy && (
            <p className="text-[18px] text-gray-500 mr-1">
              {data?.sourceBy + " | "}
            </p>
          )}
          {data?.created_at && (
            <p className="text-[14px] text-gray-500 mt-2">
              {new Date(data?.created_at).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </p>
          )}
        </div>
        <h3 className="text-[17px] font-semibold line-clamp-2">
          {data?.title}
        </h3>
      </div>
    </div>
  );
};

export default CelebrityCard;
