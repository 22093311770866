import React, { useEffect } from "react";

const BelowFeaturedImages = () => {
  // const [adDisplayed, setAdDisplayed] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
    //   const checkIframe = () => {
    //     const adContainer = document.querySelector(
    //       "[data-id='rediads_postbid_el']"
    //     );

    //     const divContainer = document.querySelector(
    //       "[id='google_ads_iframe_/23055822891/millionairclubs.in/BelowFeaturedImages_0__container__']"
    //     );

    //     // console.log("divContainer", divContainer);

    //     if (divContainer) {
    //       // const divCheck = adContainer.querySelector("div");
    //       const iframe = divContainer.querySelector("iframe");

    //       // console.log("adContainer", iframe);

    //       if (iframe) {
    //         setAdDisplayed(true);
    //         console.log("iframeAvailable")
    //       } else {
    //         setAdDisplayed(false);
    //       }
    //     }
    //   };
    //   checkIframe();
    //   // console.log("adDisplayed", adDisplayed);
    // }, 5000);

    if (window.RediAds) {
      window.RediAds.refreshAds();
    }
  }, []);
  
  return (
    <div className="flex justify-center my-5">
      <div
        data-id="rediads_postbid_el"
        data-adslot="/23055822891/millionairclubs.in/Below_Featured_Images"
        data-size-desktop="[[300,250]]"
        data-size-mobile="[[300,250]]"
        data-ad-refresh="true"
      ></div>
    </div>
  );
};

export default BelowFeaturedImages;
