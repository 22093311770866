import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import BlogPage from "../Pages/Blog-page";
import Layout from "../Components/Layout";
import AboutUs from "../Pages/about-us";
import SingleViewIdea from "../Components/single-view-idea.jsx";
import ViewFinancialIdea from "../Components/view financial-idea.jsx";
import NotFound from "../Pages/NotFound";
import Disclaimer from "../Pages/disclaimer.jsx";
import Books from "../Pages/books.jsx";
import CelebrityDetails from "../Pages/celebrity-details.jsx";
import Celebrity from "../Pages/celebrity";
import CelebrityData from "../Pages/celebrity-data";
import SingleBlogDatails from "../Pages/single-blog-details.jsx";
import ViewPerfumeData from "../Pages/viewPerfumeData.jsx";
import BillionaireShopping from "../Pages/BillionaireShopping.jsx";
import EarnerData from "../Pages/earner-single-data.jsx";
import SingleHeadline from "../Pages/single-headline.jsx";
import SuccessAllStep from "../Components/success-all-step.jsx";
import LifeStyleView from "../Pages/lifeStyle-view.jsx";
import LifeStyle from "../Pages/LifeStyle.jsx";
import TermsConditions from "../Pages/Terms-condition.jsx";
import PrivacyPolicy from "../Pages/Privacy-policy.jsx";
import SingleBlog from "../Pages/single-blog.jsx";
import Billionaires from "../Pages/Billionaires.jsx";
import SingleBillionaire from "../Pages/Single-Billionaire-detail.jsx";
import HomeTopBlog from "../Pages/HomeTopBlog.jsx";

const AllRoutes = () => {
  const [ideaLoading, setIdeaLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const authorizedDomain = ["millionairclubs.in", "www.millionairclubs.in", "new.billionairclubs.com", "localhost", "billionairclubs.com", "www.billionairclubs.com"];
    if (!authorizedDomain.includes(window.location.hostname)) {
      setShowWarning(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense>
        <div>
          {showWarning ? (
            <div
              style={{
                position: "fixed",
                top: "0",
                width: "100%",
                backgroundColor: "red",
                color: "white",
                textAlign: "center",
                padding: "10px",
                zIndex: "1000",
              }}
            >
              <strong>Warning:</strong> You are visiting an unauthorized copy of
              our site. For the official site, please go to{" "}
              <a
                href="https://millionairclubs.in"
                style={{ color: "#fff", textDecoration: "underline" }}
              >
                millionairclubs.in
              </a>
              .
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route
                  path="/"
                  element={<Home setBlogLoading={setBlogLoading} />}
                />
                <Route path="/:title" element={<HomeTopBlog />} />
                <Route path="/billionaires" element={<Billionaires />} />
                <Route
                  path="/billionaires/:name"
                  element={<SingleBillionaire />}
                />
                <Route path="/top-earner/:title" element={<EarnerData />} />
                <Route path="/headline/:title" element={<SingleHeadline />} />
                <Route path="/celebrities" element={<Celebrity />} />
                <Route
                  path="/celebrities-data/:title"
                  element={<CelebrityData />}
                />
                <Route
                  path="/celebrities-details/:title/:name"
                  element={<CelebrityDetails />}
                />
                <Route
                  path="/single-blog/:title"
                  element={<SingleBlogDatails />}
                />
                <Route
                  path="/blog"
                  element={
                    <BlogPage
                      blogLoading={blogLoading}
                      setBlogLoading={setBlogLoading}
                    />
                  }
                />
                <Route path="/blog/:title" element={<SingleBlog />} />
                <Route path="/success-step" element={<SuccessAllStep />} />
                <Route path="/life-style" element={<LifeStyle />} />
                <Route path="/view-life-style" element={<LifeStyleView />} />
                <Route
                  path="/view-financial-idea"
                  element={
                    <ViewFinancialIdea
                      ideaLoading={ideaLoading}
                      setIdeaLoading={setIdeaLoading}
                    />
                  }
                />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/books" element={<Books />} />
                <Route
                  path="/financial-idea/:topic"
                  element={<SingleViewIdea />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route
                  path="/billionaire-shopping/:title"
                  element={<BillionaireShopping />}
                />
                <Route
                  path="/view-perfume-data/:title"
                  element={<ViewPerfumeData />}
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          )}
        </div>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AllRoutes;
