import React from "react";

const adsArray = [
];

// React component with Tailwind CSS and Global Styles
const BlogWithParallax = ({ description }) => {
  const BetweenContentAd = "{{BETWEEN_CONTENT_AD}}";
  const parts = description?.split(BetweenContentAd);

  return (
    <div className="blog-content p-4">
      {/* Iterate over the content and insert ads in between */}
      {parts?.map((part, index) => (
        <div className="blog-part" key={index}>
          <div dangerouslySetInnerHTML={{ __html: part }} />
          
          {/* Insert ads if not at the last part */}
          {index < parts.length - 1 && (
            <div className="ad-slot py-5">
              {/* Display ad */}
              {adsArray[index]}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BlogWithParallax;
