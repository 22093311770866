import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Base_Url from "../api/baseUrl";
import BackToTop from "../Components/backToTop";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import ParallaxCode from "../Components/ads/ParallaxCode";
import EndOfArticle from "../Components/ads/EndOfArticle";
import NoFoundData from "../Components/Common/noFoundData";
import SocialShareButtons from "../Components/Common/SocialShareButtons";
import StickyAd from "../Components/Common/StickywithSubBLog";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import BlogWithParallax from "../Components/Common/BlogWithParallax";
import decrypt from "../Components/Common/DecryptData";

const SingleBlog = () => {
  const page = 0;
  const limit = 100;

  const location = useLocation();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState({});
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    const getOneBlog = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${Base_Url}api/blog/${formattedTitle}`);

        const { iv, end } = res.data;
        const decryptedData = decrypt(iv, end);

        const parsedData = JSON.parse(decryptedData);
        setBlog(parsedData.data ?? {});
        localStorage.setItem("returningFromBlog", "true");
      } catch (error) {
        console.error(error);
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    if (formattedTitle) {
      getOneBlog();
    }

    const clickCount = Number(localStorage.getItem("clickCount") || "0");

    if (clickCount >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  }, [formattedTitle]);

  const formattedDate = useMemo(() => {
    return blog?.created_at
      ? new Date(blog?.created_at).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      : "";
  }, [blog]);

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="container max-w-7xl mx-auto px-4">
        {!notFound ? (
          <>
            {loading ? (
              <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
                <div className="loader2 my-5"></div>
              </div>
            ) : (
              <div>
                <div className="flex justify-center pt-5">
                  <div className="text-center my-3 h-full">
                    <h1 className="text-[#000] px-[20px] text-3xl leading-snug font-semibold max-[1024px]:text-center max-[1024px]:!leading-[60px] max-[1024px]:px-0 max-[768px]:text-2xl max-[768px]:!leading-normal">
                      {blog?.title}
                    </h1>
                  </div>
                </div>
                <BelowFeaturedImages />
                <div className="flex justify-end">
                  {blog?.sourceBy && (
                    <p className="text-[14px] text-gray-500 mt-2">
                      {blog?.sourceBy + " | "}
                    </p>
                  )}
                  {formattedDate && (
                    <p className="text-[14px] text-gray-500 mt-2">
                      {formattedDate}
                    </p>
                  )}
                </div>

                <div className="flex justify-center lg:h-[550px] mb-5 w-full mx-auto">
                  <img
                    src={blog?.image}
                    className="h-full w-full object-cover"
                    alt={blog?.title}
                    loading="lazy"
                  />
                </div>

                <SocialShareButtons blogTitle={blog.title} />

                <div className="my-5">
                  <ParallaxCode />
                </div>
                <div className="flex gap-5">
                  <div className="lg:w-[70%] w-full headline-description">
                    {blog?.description && (
                      <BlogWithParallax description={blog?.description} />
                    )}
                  </div>
                  <StickyAd
                    link={"blog"}
                    url={`${Base_Url}api/get-blog?limit=${limit}&page=${
                      page + 1
                    }`}
                    StickyContent={<EndOfArticle />}
                    formattedTitle={formattedTitle}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <NoFoundData title="Ooops... No Blog found!" />
        )}
        <BackToTop />
      </div>
    </>
  );
};

export default SingleBlog;
