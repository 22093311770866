import React from 'react'
import BackToTop from '../Components/backToTop';

const TermsConditions = () => {
    return (
        <div>
            <div className='container max-w-7xl mx-auto px-4'>
                <div className='mt-5 mb-[15px] max-[768px]:mb-0'>
                    <h1 className='flex justify-center text-[#000] md:text-[40px] font-semibold text-[30px]' style={{fontFamily: "Playfair Display",}}>Terms & Conditions</h1>
                </div>
                <div className='flex justify-center'>
                    <div className='max-[768px]:text-justify'>
                        <div className='pt-4'>
                            <p className='text-[16px] md:text-[18px]'>Welcome to <b>billionairclubs.com</b>. By accessing or using this Website, you agree to comply with and be bound by the following Terms and conditions. If you do not agree to these Terms, please do not use the Website. We reserve the right to modify these Terms at any time, and such modifications will be effective immediately upon posting on the Website. It is your responsibility to review these Terms periodically to ensure you are aware of any changes.</p>
                        </div>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-3'>Use of the Website</h2>
                            <p className='text-[16px] md:text-[18px]'>You agree to use the Website for lawful purposes only. You must not use the Website in any way that causes or may cause, damage to the Website or impairment of the availability or accessibility of the Website. You must not use the Website in any way that is unlawful, fraudulent, or harmful, or in connection with any unlawful, fraudulent, or harmful purpose or activity. The Website provides information and resources on becoming rich but does not guarantee financial success.</p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>Intellectual Property</h2>
                            <p className='text-[16px] md:text-[18px]'>All content on the Website, including text, graphics, logos, images, and software, is the property of "The Ultimate Guide to Becoming a Rich" or its content suppliers and is protected by international copyright laws. You may view, download, and print pages from the Website for your personal use, subject to the restrictions set out in these Terms. You must not republish, sell, rent, or sub-license material from the Website without our express written permission.</p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>User Content</h2>
                            <p className='text-[16px] md:text-[18px]'>
                                You are responsible for any content you post on the Website, including comments, feedback, and other communications. By posting content, you grant "How to Become Rich" a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, publish, translate, and distribute your content in any existing or future media. You represent and warrant that your content does not infringe on any third party’s legal rights and that it is not unlawful or objectionable.
                            </p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>Privacy Policy</h2>
                            <p className='text-[16px] md:text-[18px]'>Your privacy is important to us. Our Privacy Policy, which sets out how we collect, use, and protect your personal information, is incorporated into these Terms by this reference. By using the Website, you consent to the processing of your data by our Privacy Policy. Please review our Privacy Policy for detailed information.</p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>Limitation of Liability</h2>
                            <p className='text-[16px] md:text-[18px]'>The information provided on the Website is for general informational purposes only. While we strive to provide accurate and up-to-date information, we make no warranties or representations of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the Website or the information contained on the Website.</p>
                            <p className='text-[16px] md:text-[18px]'>In no event will "How to Become Rich" be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Website.</p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>Indemnity</h2>
                            <p className='text-[16px] md:text-[18px]'>You agree to indemnify, defend, and hold harmless "How to Become Rich," its officers, directors, employees, agents, and third parties, for any losses, costs, liabilities, and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Website, your violation of any terms of this Agreement, or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. "How to Become Rich" reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with "How to Become Rich" in asserting any available defenses.</p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>Termination</h2>
                            <p className='text-[16px] md:text-[18px]'>We may terminate or suspend access to our Website immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Website will immediately cease. If you wish to terminate your account, you may simply discontinue using the Website. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                            <h2 className='text-[20px] lg:text-[22px] font-semibold pt-[1rem] max-[768px]:!pt-3'>Governing Law</h2>
                            <p className='text-[16px] md:text-[18px]'>These Terms shall be governed by and construed by the laws of [Your Country/State], without regard to its conflict of law principles. Any disputes arising out of or related to the use of the Website shall be subject to the exclusive jurisdiction of the courts located in india. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.</p>
                    </div>
                </div>

            </div>
            <BackToTop/>
        </div>
    )
}

export default TermsConditions