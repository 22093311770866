import CryptoJS from "crypto-js";

const originalKey = "your-strong-passphrase";

// Generate a 32-byte key using SHA-256
const secretKey = CryptoJS.SHA256(originalKey)
  .toString(CryptoJS.enc.Base64)
  .substr(0, 32);

const decrypt = (iv, encryptedData) => {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const ivBytes = CryptoJS.enc.Hex.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encryptedData),
    },
    key,
    {
      iv: ivBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export default decrypt;
