import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { useEffect } from "react";
import axios from "axios";

function App() {
  useEffect(() => {
    setTimeout(() => {
      if (window.googletag && window.googletag.pubads) {
        try {
          window.googletag.pubads().refresh();
        } catch (error) {
          console.error("Failed to refresh ads:", error);
        }
      }
    }, 8000);

    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const { latitude, longitude } = pos.coords;
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          const countryData = response.data.address?.country;
          localStorage.setItem("country", countryData);
        } catch (apiError) {
          console.log(`API Error: ${apiError.message}`);
        }
      },
      (err) => {
        console.log(`Error: ${err.message}`);
      }
    );
  }, []);

  useEffect(() => {
    (function (_window, _document) {
      var OB_ADV_ID = "00d929906df2c35176b6485002b4ebd144";
      if (_window.obApi) {
        var toArray = function (object) {
          return Object.prototype.toString.call(object) === "[object Array]"
            ? object
            : [object];
        };
        _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(
          toArray(OB_ADV_ID)
        );
        return;
      }
      var api = (_window.obApi = function () {
        api.dispatch
          ? api.dispatch.apply(api, arguments)
          : api.queue.push(arguments);
      });
      api.version = "1.1";
      api.loaded = true;
      api.marketerId = OB_ADV_ID;
      api.queue = [];
      var tag = _document.createElement("script");
      tag.async = true;
      tag.src = "//amplify.outbrain.com/cp/obtp.js";
      tag.type = "text/javascript";
      var script = _document.getElementsByTagName("script")[0];
      script.parentNode.insertBefore(tag, script);
    })(window, document);

    if (window.obApi) {
      window.obApi("track", "PAGE_VIEW");
      window.obApi("track", "PV5");
    }

    (function (_window, _document) {
      var OB_ADV_ID = "00d929906df2c35176b6485002b4ebd144";
      if (_window.obApi) {
        var toArray = function (object) {
          return Object.prototype.toString.call(object) === "[object Array]"
            ? object
            : [object];
        };
        _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(
          toArray(OB_ADV_ID)
        );
        return;
      }
      var api = (_window.obApi = function () {
        api.dispatch
          ? api.dispatch.apply(api, arguments)
          : api.queue.push(arguments);
      });
      api.version = "1.1";
      api.loaded = true;
      api.marketerId = OB_ADV_ID;
      api.queue = [];
      var tag = _document.createElement("script");
      tag.async = true;
      tag.src = "//amplify.outbrain.com/cp/obtp.js";
      tag.type = "text/javascript";
      var script = _document.getElementsByTagName("script")[0];
      script.parentNode.insertBefore(tag, script);
    })(window, document);

    if (window.obApi) {
      window.obApi("track", "PV5");
    }

    // RediAds
    if (window.RediAds) {
      window.RediAds.refreshAds();
    }
  }, []);
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
