import React, { useEffect } from "react";

const LongAd03 = () => {
  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.async = true;
      script.onload = initializeGoogleAds;
      document.head.appendChild(script);
    };

    const initializeGoogleAds = () => {
      if (window.googletag && typeof window.googletag === "object") {
        window.googletag.cmd.push(() => {
          // Create size mapping for different screen sizes
          const mapping = window.googletag
            .sizeMapping()
            .addSize([1024, 768], [728, 90]) // Large screens
            .addSize([768, 600], [728, 90]) // Medium screens
            .addSize([320, 400], [320, 50]) // Small screens
            .build();

          // Define the ad slot with the size mapping
          const adSlot = window.googletag
            .defineSlot(
              "/23055822891/millionair_sticky",
              [[728, 90]],
              "div-gpt-ad-1721020427956-0"
            )
            .defineSizeMapping(mapping)
            .addService(window.googletag.pubads());

          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
          window.googletag.display("div-gpt-ad-1721020427956-0");
        });
      } else {
        console.error(
          "googletag is not defined. Google Ad Manager library might not be loaded."
        );
      }
    };

    loadGoogleAdsScript();
  }, []);

  return (
    <>
      <p style={{ textAlign: "center", fontSize: "10px", paddingTop: "30px" }}>
        ADVERTISEMENT
      </p>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "2px" }}
      >
        <div
          id="div-gpt-ad-1721020427956-0"
          style={{
            minWidth: "320px",
            minHeight: "50px",
            margin: "0 10px",
            overflow: "hidden",
            backgroundColor: "#E9EAEC",
          }}
        ></div>
      </div>
      <p
        style={{ textAlign: "center", fontSize: "10px", paddingBottom: "30px" }}
      >
        ADVERTISEMENT
      </p>
    </>
  );
};

export default LongAd03;
