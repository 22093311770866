import React, { useEffect, useState } from "react";
import { BsChevronDoubleUp } from "react-icons/bs";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className="md:block hidden">
      {isVisible && (
        <button
          aria-label="top_btn"
          onClick={scrollToTop}
          className="fixed bottom-28 font-semibold right-10 z-50 bg-black hover:bg-[#282828] text-white h-[40px] w-[40px] flex justify-center items-center rounded-[10px] shadow-lg"
        >
          <BsChevronDoubleUp />
        </button>
      )}
    </div>
  );
};

export default BackToTop;
