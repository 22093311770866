import React from 'react'

const NotFound = () => {
  return (
    <div> <div className='h-[70vh] flex justify-center flex-col text-center max-[1024px]:h-[67vh]'>
      <h1 className='text-[90px] text-black max-[768px]:text-[94px] max-[768px]:mb-0'>404</h1>
      <h2 className='text-3xl mb-1 text-black font-semibold max-[768px]:text-[25px]'>Ooops... Page Not Found!</h2>
      <div className='flex justify-center mt-3'>
        <a href='/' className='no-underline'>
          <button className='text-xl underline font-medium  bg-[#F06939] text-white w-[220px] rounded-md h-[60px] mt-2'>Go Back</button>
        </a>
      </div>
    </div></div>
  )
}

export default NotFound