import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { FaBars } from "react-icons/fa";
import logo from "../Assets/billinaireclub-04.png"

const Header = () => {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const handleToggleOpen = () => setIsToggleOpen(!isToggleOpen);

  return (
    <div className="text-white bg-[#404040]">
      <header className="h-[80px] container max-w-7xl mx-auto w-full flex items-center relative">
        <div className="px-3">
          <a href="/" className="text-[24px] text-white font-semibold">
            <img
              src={logo}
              alt="logo"
            />
          </a>
        </div>

        <ul
          className={`flex justify-center w-full ${
            isToggleOpen ? "listing-toggle-open" : "listing-toggle-closed"
          }`}
        >
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/`;
              setIsToggleOpen(false);
            }}
          >
            Home
          </li>
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/billionaires`;
              setIsToggleOpen(false);
            }}
          >
            Billionaires
          </li>
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/blog`;
              setIsToggleOpen(false);
            }}
          >
            Blogs
          </li>
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/celebrities`;
              setIsToggleOpen(false);
            }}
          >
            Celebrities
          </li>
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/life-style`;
              setIsToggleOpen(false);
            }}
          >
            LifeStyle
          </li>
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/about-us`;
              setIsToggleOpen(false);
            }}
          >
            About Us
          </li>
          <li
            className="text-white block p-3 px-4 font-semibold cursor-pointer"
            onClick={() => {
              window.location.href = `/books`;
              setIsToggleOpen(false);
            }}
          >
            Books
          </li>
        </ul>

        <div className="xl:hidden absolute right-[20px] top-[30px]">
          <button
            onClick={handleToggleOpen}
            aria-label="toggle_btn"
            className="text-white text-[24px] cursor-pointer"
          >
            {isToggleOpen ? <GrClose /> : <FaBars />}
          </button>
        </div>
      </header>
    </div>
  );
};

export default Header;
